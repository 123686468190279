import React from 'react';
import { palette } from '../../../../theme/palette';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import SubmissionsIcon from '../../../../components/Icons/SubmissionsIcon';
import CompletedIcon from '../../../../components/Icons/CompletedIcon';
import AverageIcon from '../../../../components/Icons/AverageIcon';
import { getMonthName } from '../../../../components/AdminGraphs/CommonFunctions';

interface Props {
  active?: string,
  payload?: any,
}

const style = {
  dialogBox: {
    padding: 10,
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customRed.progressPrimary}`,
    width: 'auto',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: `0 3px 5px 1px ${palette.customBackground.disabled}`,
  },
};

const CustomTooltip = ({ active, payload }:Props) => {
  const dataVar = payload?.[0]?.payload;
  const calendarMonth = getMonthName(dataVar?.month);
  const totalUnits = dataVar?.unique_student_completed_pts_count;
  const consumedUnits = dataVar?.most_recent_goals_count;
  const average = dataVar?.average_no_of_most_recent_goals_count.toFixed(2);
  const displayYear = dataVar?.year;

  if (active && totalUnits != null) {
    return (
      <Box className="line-chart-tooltip" style={style.dialogBox}>
        <Typography
          component='p'
          sx={{
            fontSize:'16px',
            fontWeight:600,
            color: palette.customGrey.calendarGrey,
            mb:'5px',
          }}
        >
          {calendarMonth} {displayYear}
        </Typography>
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SubmissionsIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="selectPlaceholder">{totalUnits} Submissions</Typography>}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CompletedIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="selectPlaceholder">{consumedUnits} Goals</Typography>}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AverageIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="selectPlaceholder">{average} Average</Typography>}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    );
  }

  return null;
};

export default CustomTooltip;
