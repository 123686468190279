import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  SubmissionStatus,
  useBenchmarkSubmissionQuery,
  useAnchorToggleStatusQuery,
  useAnchorToggleUpdateMutation,
  useUpdateBenchmarkFeedbackNotesMutation,
  useMarkBenchmarkReviewCompleteMutation,
  useUpdateBenchmarkScoreMutation,
} from '../../generated/graphql';
import {
  SUBMISSION_ALREADY_REVIEWED_MESSAGE,
  SUBMISSION_ALREADY_REVIEWED_ERROR,
} from '../../variables/constant';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType, ScoreTabs } from '../../variables/types';
import useBenchmarksQueueHook from './score-benchmark-queue-hook';
import useSidebarHook from '../../components/Sidebar/sidebar-hook';
import useRole from '../../hooks/useRole';

interface RouteProp {
  id: string;
  section?: string;
}

const getIndexBySection = (section?: string) => {
  if (!section || section === ScoreTabs.Speaking) {
    return 0;
  }
  if (section === ScoreTabs.Writing) {
    return 1;
  }
  return 2;
};

const useScoreBM = () => {
  const { isTeacher } = useRole();
  const history = useHistory();
  const { id, section } = useParams<RouteProp>();
  const [tab, setTab] = useState(getIndexBySection(section));
  const [expanded, setExpanded] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isAnchorPlaying, setIsAnchorPlaying] = useState(false);
  const [notes, setNotes] = useState('');

  const { setQueueCounter } = useBenchmarksQueueHook();

  const {
    teacherBenchmarksDataRefetch: refetchNavbarBenchmarkSubmissionCount,
    adminBenchmarkCountRefetch,
  } = useSidebarHook();

  const { data: anchor, refetch: anchorRefetch } = useAnchorToggleStatusQuery({
    fetchPolicy: 'no-cache',
  });
  const anchorEnabled = anchor?.anchorToggle;

  const { data: submissionData, loading: submissionLoading, refetch: submissionsRefetch } = useBenchmarkSubmissionQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: id!,
    },
  });
  const submission = submissionData?.benchmarkSubmission;

  const scoreSummaryOptions = (submission?.scoring_details?.map((scoreDetail) => ({
    id: scoreDetail?.id,
    value: scoreDetail?.id,
    label: scoreDetail?.score_value.toString(),
  }))) ?? [];

  const [anchorToggle, { loading: updateToggle }] = useAnchorToggleUpdateMutation();
  const [updateScore, { loading: updating }] = useUpdateBenchmarkScoreMutation();
  const [updateFeedbackNotes] = useUpdateBenchmarkFeedbackNotesMutation();
  const [markReviewCompleteMutation, { loading: submitting }] = useMarkBenchmarkReviewCompleteMutation();

  const handleAnchorToggleUpdate = async (value: boolean) => {
    try {
      await anchorToggle({
        fetchPolicy: 'network-only',
        variables: {
          showToggle: value,
        },
      });
      await anchorRefetch();
    } catch (err) {
      openSnackbar({ message: 'Show Anchor value was not updated' }, NotifierType.Error);
    }
  };

  const handleScoreUpdate = async (gradeId: string, scoreDetailId: string) => {
    try {
      await updateScore({
        variables: {
          ...(isTeacher && { submissionId: id }),
          gradeId,
          scoreDetailID: scoreDetailId,
        },
      });

      submissionsRefetch();
    } catch (error) {
      showError(error);
    }
  };

  const saveNotes = async (): Promise<string | undefined> => {
    var saveNotesErr = undefined;
    try {
      const response = await updateFeedbackNotes({
        variables: {
          id,
          notes,
        },
      });
      if (!response.data?.updateBenchmarkFeedbackNotes?.id) {
        saveNotesErr = 'Error in updating notes.';
        openSnackbar({ message: saveNotesErr }, NotifierType.Error);
      }
    } catch (error) {
      showError(error);
      saveNotesErr = getErrorMessage(error);
    }
    return saveNotesErr;
  };

  const handleSubmit = async (submissionId: string) => {
    try {
      const response = await markReviewCompleteMutation({
        variables: {
          id: submissionId,
        },
      });
      let markReviewCompleteStatus = response.data?.markBenchmarkReviewComplete?.status;
      if (!markReviewCompleteStatus || markReviewCompleteStatus !== SubmissionStatus.Reviewed) {
        openSnackbar({ message: 'Error in submitting review.' }, NotifierType.Error);
      } else setQueueCounter({ graded: 1, goalsSet: 0 });
    } catch (error) {
      showError(error);
    }
  };

  const showError = async (error: any) => {
    if (getErrorMessage(error) === SUBMISSION_ALREADY_REVIEWED_ERROR) {
      openSnackbar({
        message: SUBMISSION_ALREADY_REVIEWED_MESSAGE,
      }, NotifierType.Error);
      history.push('/');
      if (isTeacher) await refetchNavbarBenchmarkSubmissionCount();
      else await adminBenchmarkCountRefetch();
    } else {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  const togglePreview = () => setShowPreview(!showPreview);

  useEffect(() => {
    setTab(getIndexBySection(section));
    setNotes(submission?.feedback_notes ?? '');
  }, [section, submission]);

  return {
    id,
    section,
    tab,
    setTab,
    expanded,
    setExpanded,
    loading: submissionLoading,
    submission,
    handleScoreUpdate,
    updating,
    handleSubmit,
    submitting,
    showPreview,
    togglePreview,
    submissionsRefetch,
    scoreSummaryOptions,
    handleAnchorToggleUpdate,
    anchorEnabled,
    updateToggle,
    anchorRefetch,
    notes,
    setNotes,
    saveNotes,
    isAnchorPlaying,
    setIsAnchorPlaying,
  };
};

export default useScoreBM;
