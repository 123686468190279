import React from 'react';
import { Box, Typography } from '@mui/material';
import FeedbackRow from '../ScoreTask/Common/StudentFeedback/FeedbackRow';
import BodyContainer from '../ScoreTask/BodyContainer';
import ScoringCard from '../ScoreTask/Common/ScoringCardWithTooltip';
import ClickAndLabelTask from '../SubmitTask/ClickAndLabel/ClickAndLabelTask';
import { ScoreTabs } from '../../variables/types';
import TeacherNotes from '../ScoreTask/Common/TeacherNotes';
import { StyledContainer2 } from '../ScoreTask/ScoreTask.styled';
import BackdropModal from '../../components/BackdropModal/BackdropModal';
import useAdditionalFeedbackBM from './additional-feedback-hook';
import useScoreBM from './score-benchmark-hook';
import ScoreTaskLayoutBM from './ScoreTaskLayoutBM';
import ScoringHeader from '../ScoreTask/Common/ScoringHeader';
import WritingBody from '../ScoreTask/Writing/WritingBody';
import PTPromptCardBM from './PTPromptCardBM';

const WritingBM = () => {
  const {
    id,
    submission,
    setNotes,
    saveNotes,
    showPreview,
    togglePreview,
    notes: teacherNotes,
    loading: scoreLoading,
  } = useScoreBM();
  const {
    notes: feedbackNotes,
    onSave,
    loading: additionalFeedbackLoading,
    onDelete,
    onRecord,
    audioData,
    isPlaying,
    updateNotes: saveAdditionalFeedback,
    handleStartRecording,
  } = useAdditionalFeedbackBM({
    id: id ?? '',
    textFeedback: submission?.additional_feedback?.feedback_notes ?? '',
    audioFeedback: submission?.additional_feedback?.audio?.[0] || null,
  });

  const loading = additionalFeedbackLoading || scoreLoading;

  const bodyContent = (
    <>
      <Box>
        <Box>
          <StyledContainer2>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                gridRowGap: 10,
              }}
            >
              <Box ml="20px" mt="20px">
                <Typography variant='sContentHeader'>{submission?.student?.first_name ? `${submission?.student?.first_name}'s Submission` : '' }</Typography>
              </Box>
              <Box>
                <ClickAndLabelTask
                  togglePreview={togglePreview}
                  submission={submission}
                  annotations={submission?.annotationsBM || []}
                  disableEdit
                  containerHeight="40vh"
                />
              </Box>
            </Box>
          </StyledContainer2>
        </Box>
        <Box mt="30px">
          <PTPromptCardBM taskType={ScoreTabs.Writing} benchmark={submission?.benchmark}/>
        </Box>
        <Box mt="30px">
          <ScoringHeader
            title="Student Writing"
          />
          <WritingBody notes={submission?.writing ?? ''} />
        </Box>
      </Box>
      <Box>
        <Box>
          <ScoringCard taskType={ScoreTabs.Writing} />
        </Box>
        <Box mt="30px">
          <FeedbackRow
            loading={loading}
            onRecord={onRecord}
            audioData={audioData}
            isPlaying={isPlaying}
            notes={feedbackNotes}
            updateNotes={saveAdditionalFeedback}
            onDelete={onDelete}
            handleStartRecording={handleStartRecording}
            submissionStatus={submission?.status ?? ''}
          />
        </Box>
        <Box mt="30px">
          <TeacherNotes
            submissionStatus={submission?.status ?? ''}
            setFeedback={setNotes}
            feedback={teacherNotes}
          />
        </Box>
      </Box>
      <BackdropModal open={showPreview} onClose={togglePreview}>
        <ClickAndLabelTask
          submission={submission}
          annotations={submission?.annotationsBM || []}
          disableEdit
          containerHeight="80vh"
        />
      </BackdropModal>
    </>
  );
  return (
    <ScoreTaskLayoutBM
      saveTeacherNotes={saveNotes}
      saveTeacherFeedback={onSave}
      body={<BodyContainer bodyContent={bodyContent} />}
    />
  );
};
export default WritingBM;
